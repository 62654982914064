<template>
    <b-modal id="modal-config-painel" hide-footer size="lg">
        <div class="spaceModal">
            <div class="titleModal">Configurar Projeto</div>
            <div class="spaceSelectPainel">
                <div class="spacePainels">
                    <div class="spaceInputs">
                        <b-form-group label="Nome do Projeto" label-for="name" id="step4">
                            <b-form-input
                            v-model="nameProject"
                            placeholder="Nome do Projeto"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="spaceInputs">
                        <b-form>
                            <b-form-group label="Subdomínio" label-for="name">
                                <b-form-input
                                id="subdomainField"
                                name="subdomainField"
                                v-model="subdomain"
                                placeholder="Subdomínio"
                                v-validate="{
                                    required: true,
                                    regex: /^[a-z\-0-9]{6,30}$/i,
                                }"
                                ></b-form-input>
                                <b-form-invalid-feedback :state="!errors.has('subdomainField')">
                                {{ $t("modal_new_panel.feedback_menssage_project_slug") }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form>
                    </div>
                    <div class="spaceInputs">
                        <b-form-group
                            label="Escreva uma descrição sobre o Projeto"
                            label-for="resumo"
                        >
                            <b-form-textarea
                            id="textarea"
                            v-model="descricaoProject"
                            placeholder="Escreva uma descrição sobre o Projeto"
                            rows="3"
                            max-rows="6"
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                </div>
                <div class="header">
                    <button class="newPainel" @click="checkSubdomain">Salvar Projeto</button>
                </div>
            </div>
        </div>
        <ModalAlertSubdomain
            :subdomains="{ new: subdomain, current: current_subdomain }"
            @accept="subdomain => saveProject(subdomain)"
            @decline="current => subdomain = current"
        />
    </b-modal>
</template>
<script>
import notify from "@/services/libs/notificacao";
import axios from "axios";
import Cookies from "js-cookie";
import ModalAlertSubdomain from "./ModalAlertSubdomain.vue"
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
    components: {
        ModalAlertSubdomain
    },
    props: ["type"],
    data(){
        return{
            nameProject:"",
            descricaoProject:"",
            currentSiteId:'',
            subdomain: '',
            current_subdomain: '',
        }
    },
    mounted(){
        this.$root.$on("openeditproject", () => {
        this.getCurrentSite();
        });
    },
    methods:{
        getCurrentSite(){
            serviceMember
            .read('/me')
            .then((resp) => {
                this.currentSiteId = resp.current.currentSite.id;
                this.subdomain = resp.current.currentSite.subdomain;
                this.current_subdomain = resp.current.currentSite.subdomain;
                this.nameProject = resp.current.currentSite.name;
                this.descricaoProject = resp.current.currentSite.description;
            });
        },
        saveProject(subdomain){
            this.$validator.validateAll().then(result => {
                if (result) {
                    var data = {
                        name: this.nameProject,
                        description: this.descricaoProject,
                        subdomain,
                    };

                    var that = this;
                    this.$root.$emit('loadOn');
                    axios.post(process.env.VUE_APP_API_HOST+`/site/`+this.currentSiteId, data, {headers: {
                        'Authorization': Cookies.get('auth_greennCourse')
                    }})
                    .then(function () {
                        notify('sucesso', "Alterações salvas com sucesso!");
                        that.$root.$emit('bv::hide::modal', 'modal-config-painel', '#btnShow')
                        that.$root.$emit('loadOff');
                    }).catch(err => {
                        var error = err.response.data;
                        var msg = '';
                        for (var indice in error) {
                            msg += error[indice][0] + "<br>";
                        }
                        if (msg !== ''){
                            notify('erro', msg);
                        }
                        that.$root.$emit('loadOff');
                    });
                }
            })
        },
        checkSubdomain() {
            if (this.subdomain !== this.current_subdomain) {
                this.$root.$emit('bv::show::modal', 'modal-alert-subdomain', '#btnShow')
                return;
            }
            this.saveProject(this.subdomain);
        }
    },
}
</script>
<style lang="scss">
    #modal-config-painel{
        .textSlug{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #000000;
            margin-top: 12px;
            margin-left: 4px;
        }
        .spaceInputs{
            margin-bottom: 30px;
            select{
                border: 1px solid var(--bordercolor);
                box-sizing: border-box;
                border-radius: 5px;
                height: 55px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #6d767e;
                overflow:hidden !important;
                resize:none !important;
            }
            input, textarea{
                border: 1px solid var(--bordercolor);
                box-sizing: border-box;
                border-radius: 5px;
                height: 55px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: var(--fontcolor);
                overflow:hidden !important;
                resize:none !important;
                background: white;
            }
            input:hover, select:hover, textarea:hover, input:focus, select:focus, textarea:focus{
                border: 1px solid var(--maincolor);
                box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: var(--fontcolor);
            }
            label{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: var(--fontcolor);
            }
        }
        .header{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        .newPainel{
            background: var(--maincolor);
            border: 1px solid var(--maincolor);
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
            border-radius: 5px;
            height: 55px;
            width: 250px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            padding: 0px 15px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .modal-header {
            border: none !important;
        }
        .spaceModal{
            padding: 5px 30px 0px 30px;
            margin-top: -20px;
            .logout{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #FF0C37;
                margin-top: 10px;
                cursor: pointer;
            }
            .logout2{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: var(--maincolor);
                margin-top: 10px;
                cursor: pointer;
            }
            .titleModal{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                letter-spacing: 0.2px;
                color: var(--fontcolor);
            }
            .spaceSelectPainel{
                background: var(--backgroundcolor);
                // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                padding: 20px 5px;
                .lineDiv{
                    height: 0.5px;
                    background: #EDEDF0;
                    width: 100%;
                    margin-bottom: 25px;
                    margin-top: 25px;
                }
                .flexData{
                    display: flex;
                    margin-bottom: 20px;
                    cursor: pointer;
                    .pointer{
                    background: rgba(0, 214, 228, 0.1);
                    border: 1px solid #00D6E4;
                    box-sizing: border-box;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    }
                    .title{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #333333;
                    margin-left: 20px;
                    line-height: 40px;
                    }
                }
            }
        }
    }
</style>
