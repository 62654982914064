<template>
  <div class="allPlans">
    <div class="mt-5 mb-4">
      <h1>Liberar Acesso Gratuito</h1>
      <p class="text">
        Aqui você pode selecionar um curso ou uma assinatura para liberar acesso
        gratuito para seus alunos.
      </p>
    </div>
    <div>
      <div class="spaceColor animationOpacity2 ml-4" id="changemoderation">
        <div class="text2 mb-2">Acesso Gratuito</div>
        <div class="subTitle">
          Se essa opção estiver ativa, você podera escolher turmas ou
          assinaturas para liberar gratuitamente.
        </div>
        <div class="flex mt-2">
          <toggle-button
            v-model="gratuito"
            @input="updateFreeCourse"
            :sync="true"
          />
          <div class="text ml-3">Ativar Acesso Gratuito</div>
        </div>
        <div v-if="gratuito" class="mt-3">
          <b-form-group label="Tipo para Liberação" label-for="fonte">
            <b-form-select
              v-model="typeSelect"
              placeholder="Selecione uma Liberação"
              :options="options"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            v-if="typeSelect === 'course_class'"
            label="Selecione uma Turma"
            label-for="fonte"
          >
            <multiselect
              @open="openCourse"
              class="item"
              placeholder="Turmas"
              selectedLabel=""
              deselectLabel=""
              tagPlaceholder=""
              selectLabel=""
              track-by="id"
              :multiple="false"
              :taggable="false"
              :custom-label="customLabel"
              v-model="selectCourse"
              :options="AllClass"
            >
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfList2" />
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group
            v-if="typeSelect === 'signature'"
            label="Selecione uma Assinatura"
            label-for="fonte"
          >
            <span>
              <multiselect
                @open="openSignature"
                placeholder="Assinaturas"
                selectedLabel=""
                deselectLabel=""
                tagPlaceholder=""
                selectLabel=""
                track-by="id"
                :multiple="false"
                :taggable="false"
                :custom-label="customLabel"
                v-model="selectAssing"
                :options="AllSignature"
              >
                <template slot="afterList">
                  <div v-observe-visibility="reachedEndOfList" />
                </template>
              </multiselect>
            </span>
          </b-form-group>
          <div>
            <BaseButton
              v-if="selectAssing || selectCourse"
              @click="salvarDados"
              variant="primary"
              class="mb-4 mt-4"
              >Salvar Dados</BaseButton
            >
          </div>
          <div class="spaceColor animationOpacity2" id="changemoderation">
            <div class="text2 mb-2">Integração G Digital</div>
            <div class="subTitle">
              Se essa opção estiver ativa, você podera salvar seus leads na G
              Digital.
            </div>
            <div class="flex mt-2">
              <toggle-button
                v-model="gdigitaltoggle"
                @input="updateGDigital"
                :sync="true"
              />
              <div class="text ml-3">Ativar Integração G Digital</div>
            </div>
            <div v-if="gdigitaltoggle">
              <section class="container-arquivos">
                <div class="lista-entrega lista-arquivos mt-4">
                  <!-- gDigital -->
                  <div>
                    <div class="d-flex justify-content-between">
                      <h4 class="title-inter">• G Digital</h4>
                    </div>
                    <b-form data-vv-scope="gdigital" v-if="!existCallback">
                      <div
                        data-anima="top"
                        class="codigo-verifica"
                        v-if="(!existCallback && !integration) || !token"
                      >
                        <div v-if="novo_email == false" data-anima="top">
                          <p>
                            Enviamos um código de verificação no e-mail:
                            {{
                              email ||
                              "lucas.rcastro1@gmail.com" ||
                              $store.getters.currentSite.currentMember.email
                            }}
                            para iniciarmos a integração.
                          </p>
                          <b-form-group
                            label="Código de verificação"
                            label-for="code_verification"
                          >
                            <b-form-input
                              v-model="code"
                              name="code_verification"
                              id="code_verification"
                              placeholder="Insira o código de verificação"
                              autocomplete="code_verification"
                              v-validate="'required'"
                            />
                            <b-form-invalid-feedback
                              :state="!errors.has('gdigital.code_verification')"
                            >
                              O código de verificação é obrigatório
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div v-if="novo_email" data-anima="top">
                          <p v-if="g_email">
                            Preencha o campo abaixo com o seu novo e-mail. Após
                            preencher, clique em 'enviar código'.
                          </p>
                          <p v-else>
                            Verificamos que não existe integração com a G
                            Digital.
                            <br />
                            Tentamos enviar um código de verificação no e-mail
                            {{ $store.state.user.user.email }}, porém o mesmo
                            não localizado na G Digital. <br /><br />
                            Insira um novo email e clique em 'enviar código'.
                          </p>
                          <b-form-group
                            label="Insira o novo e-mail"
                            label-for="novo_email"
                          >
                            <b-form-input
                              v-model="email"
                              name="novo_email"
                              id="novo_email"
                              placeholder="Insira o novo endereço de email"
                              autocomplete="novo_email"
                              v-validate="'required|email'"
                            />
                            <b-form-invalid-feedback
                              :state="!errors.has('gdigital.novo_email')"
                            >
                              Novo e-mail obrigatório
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <a
                            href="#"
                            class="sem-codigo"
                            @click.prevent="novo_email = !novo_email"
                            >{{
                              novo_email
                                ? "Usar o e-mail do Greenn"
                                : "Adicionar outro e-mail"
                            }}</a
                          >
                          <a
                            href="#"
                            class="sem-codigo"
                            v-if="!novo_email"
                            @click.prevent="reenviarCodigoGdigital"
                            >Não recebeu o código?</a
                          >
                          <a href="#" class="sem-codigo" v-else></a>
                        </div>
                        <div class="d-flex">
                          <BaseButton
                            variant="black"
                            class="mt-3"
                            :disabled="loading"
                            @click="validaCodigo"
                            >Validar código de verificação</BaseButton
                          >
                          <BaseButton
                            variant="black"
                            class="ml-3 mt-3"
                            v-if="novo_email"
                            @click="enviar_codigo"
                            >Enviar código</BaseButton
                          >
                        </div>
                      </div>

                      <b-row v-if="integration && token" class="d-flex">
                        <b-col cols="6" data-anima="top">
                          <b-form-group
                            label="Selecione o Projeto G Digital"
                            label-for="blogs"
                          >
                            <b-form-select
                              v-model="blog"
                              :options="blogs_gdigital"
                              name="blogs"
                              id="blogs"
                              placeholder="Selecione o projeto"
                              @change="changeBlogs"
                            >
                            </b-form-select>
                            <b-form-invalid-feedback
                              :state="!errors.has('gdigital.blogs')"
                            >
                              Nenhum projeto selecionado
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="select_blog" data-anima="top">
                          <b-form-group
                            label="Selecione o Formulário"
                            label-for="forms_gdigital"
                          >
                            <multiselect
                              v-model="form_id"
                              :options="forms"
                              :value="form_id"
                              :multiple="false"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true"
                              placeholder="Selecione o Formulários"
                              label="text"
                              track-by="text"
                              selectLabel=""
                              deselectLabel=""
                              selectedLabel="✔️"
                              id="multi_select_forms_gdigital"
                              name="forms_gdigital"
                              v-validate="'required'"
                            >
                            </multiselect>
                            <b-form-invalid-feedback
                              :state="!errors.has('gdigital.forms_gdigital')"
                            >
                              Nenhum formulário selecionado
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="integration && token"
                          cols="12"
                          data-anima="top"
                        >
                          <BaseButton
                            variant="primary"
                            :disabled="loading"
                            @click="initCreateCallBack"
                          >
                            Salvar Dados
                          </BaseButton>
                        </b-col>
                        <div
                          class="d-flex justify-content-center"
                          v-if="loading"
                        >
                          <b-spinner label="Loading..."></b-spinner>
                        </div>
                      </b-row>
                    </b-form>
                    <div v-else>
                      <ul v-if="lista.length" class="mt-3">
                        <li
                          v-for="item in lista"
                          :key="item.name + item.id"
                          data-anima="bottom"
                        >
                          <div>
                            <img
                              src="@/assets/icons/memberkit-icon.svg"
                              class="icon-file"
                            />
                            <div class="info-list">
                              <span>
                                #{{ item.id }} - Acesso Gratuito no Formulario -
                                {{ JSON.parse(item.args).id_form }}
                              </span>
                            </div>
                          </div>
                          <div>
                            <img
                              src="@/assets/icons/lixeira.svg"
                              class="icon-lixeira"
                              @click="removeGdigital(index, item.id, item.name)"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { ToggleButton } from "vue-js-toggle-button";
import { api_gdigital } from "@/services/Gdigital.js";
import notify from "@/services/libs/notificacao";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import ClassService from "@/services/resources/ClassService";
const serviceClass = ClassService.build();
import SignatureService from "@/services/resources/SignatureService";
const serviceSignature = SignatureService.build();
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  components: {
    ToggleButton,
    Multiselect,
  },
  data() {
    return {
      loading: false,
      gdigitaltoggle: false,
      gdigital: {
        integration: false,
        token: "",
        auth: "",
        email: "",
        blogs_gdigital: [],
        forms: [],
      },
      selectAssing: [],
      selectCourse: [],
      gratuito: false,
      typeSelect: null,
      pageActualClass: 1,
      pageActualSignature: 1,
      lastPageSignature: 1,
      AllSignature: [],
      lastPageClass: 1,
      AllClass: [],
      options: [
        { value: null, text: "Selecione um tipo para Liberação" },
        { value: "course_class", text: "Turmas" },
        { value: "signature", text: "Assinaturas" },
      ],
      integration: false,
      token: "",
      novo_email: false,
      email: "",
      blog: "",
      code: "",
      trigger: [],
      form_id: -1,
      select_blog: false,
      forms: [],
      blogs_gdigital: [],
      g_email: true,
      lista: [],
      existCallback: false,
    };
  },
  mounted() {
    this.getMeta();
    this.getCallbacks();
  },
  methods: {
    removeGdigital(index_lista, id, name) {
      this.loading = true;

      let data = {
        id: id,
      };
      serviceCallback
        .destroy(data)
        .then((response) => {
          if (response == 1) {
            this.$bvToast.toast("Evento removido com sucesso!", {
              title: "G Digital",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            // atualizando front
            this.lista.splice(index_lista, 1);
          } else {
            this.$bvToast.toast("Houve um erro ao remover o Evento", {
              title: "G Digital",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCallbacks() {
      this.loading = true;
      serviceCallback
        .read("?type=gdigital")
        .then((r) => {
          if (r.length) {
            this.lista = r;
            this.gdigitaltoggle = true;
            this.existCallback = true;
          } else {
            this.gdigitaltoggle = false;
            this.existCallback = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    // adicionar uma nova callback
    initCreateCallBack() {
      return new Promise((resolve) => {
        this.$validator.validateAll("gdigital").then((result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            const newForm = this.form_id.value;
            if (newForm) {
              let data = {
                id_form: newForm,
                type: "gdigital",
                callback: "gdigitalFreeRegistration",
                trigger: "freeRegistration",
              };
              this.postCallback(data);
            } else {
              this.loading = false;
              this.$bvToast.toast(`Selecione o Formulário para continuar`, {
                title: "G Digital",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },
    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(`Integração salva com sucesso`, {
            title: "G Digital",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // evento change par atualizar o auth (forms)
    async changeBlogs() {
      this.select_blog = true;
      const array = this.blog.toString().split("|");
      await this.getAuthGdigital(array[0], array[1]);
      await this.getFormsGdigital();
    },
    getFormsGdigital() {
      this.loading = true;
      api_gdigital
        .get(`/forms`)
        .then((r) => {
          // this.forms = r.data;
          [...r.data].forEach((item) => {
            this.forms.push({
              value: item.ID,
              text: item.post_title,
            });
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          //console.log(error);
        });
    },
    // atualizar o auth (forms) da g digital
    getAuthGdigital(id_blog, auth_blog) {
      this.loading = true;
      const blog_id = +id_blog;
      const auth = auth_blog;
      const email =
        this.email || $store.getters.currentSite.currentMember.email;
      const token = this.token;

      api_gdigital
        .get(
          `/auth?blog_id=${blog_id}&auth=${auth}&email_gdigital=${email}&token=${token}`
        )
        .then((r) => {
          this.integration = true;
          this.auth = r.data.auth;
          this.getFormsGdigital();
          this.$emit("codeGdigitalChecked");

          this.submit = false;
          this.loading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.submit = false;
          this.loading = false;
          this.integration = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validaCodigo() {
      this.$validator.validateAll("gdigital").then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          api_gdigital
            .get(`/token/${this.code}`)
            .then((r) => {
              this.token = r.data;
              this.integration = true;
              this.$bvToast.toast(
                "Código de verificação validado com sucesso",
                {
                  title: "G Digital",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );

              // começar ajustar daqui Novo fluxp
              this.getBlogsGdigital();
            })
            .catch((error) => {
              this.submit = false;
              this.loading = false;
              //console.log(error);
              // this.$bvToast.toast(
              //   "Ocorreu um erro na Validação do Código (expirado ou inválido)",
              //   {
              //     title: "G Digital",
              //     variant: "danger",
              //     autoHideDelay: 5000,
              //     appendToast: true,
              //   }
              // );
            });
        }
      });
    },
    // envia o codigo de verificação com um novo email
    enviar_codigo() {
      this.reenviarCodigoGdigital();
      this.novo_email = false;
    },

    // reenvia o codigo de verificação
    reenviarCodigoGdigital() {
      this.emailGdigital();
      this.$bvToast.toast("Código de verificação enviado!", {
        title: "G Digital",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    getBlogsGdigital() {
      const email =
        this.email || $store.getters.currentSite.currentMember.email;
      this.blogs_gdigital = [];
      api_gdigital
        .get(`/blogs?email=${email}`)
        .then((response) => {
          [...response.data].forEach((item) => {
            this.blogs_gdigital.push({
              value: `${item.blog_id}|${item.auth}`,
              text: item.blogName || item.domain,
            });
          });
          this.loading = false;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    codeGdigitalChecked() {
      api_gdigital.get("/integration").then((r) => {
        if (r.data.length) {
          this.gdigital.integration = true;
          this.gdigital.auth = r.data[0].auth;
          this.gdigital.token = r.data[0].token_greenn;
          this.gdigital.email = r.data[0].email;
          this.gdigital.blogs_gdigital = [];
          this.getBlogsGdigital();
        }
      });
    },
    emailGdigital() {
      this.loading = true;
      const email =
        this.email || $store.getters.currentSite.currentMember.email;
      api_gdigital
        .get(`/email?email=${email}`)
        .then((r) => {
          this.g_email = true;
        })
        .catch((error) => {
          //console.log(error);
          this.g_email = false;
          this.novo_email = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateGDigital() {
      await this.codeGdigitalChecked();

      if (this.gdigital.integration) {
        this.integration = this.gdigital.integration;
        this.token = this.gdigital.token;
        this.email = this.gdigital.email;
        this.blogs_gdigital = this.gdigital.blogs_gdigital;
        this.loading = false;
      } else {
        this.emailGdigital();
      }
    },
    getMeta() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=free_courses&keys[]=type&keys[]=item_free_id")
        .then((resp) => {
          //console.log("meta comments", resp);
          var data = resp.free_courses;
          if (data === "off") {
            this.gratuito = false;
          } else {
            this.gratuito = true;
          }
          if (resp.type) {
            this.typeSelect = resp.type;
          }
          if (resp.item_free_id) {
            if (resp.type === "signature") {
              this.selectAssing = JSON.parse(resp.item_free_id);
            } else {
              this.selectCourse = JSON.parse(resp.item_free_id);
            }
          }
          //console.log(this.gratuito);
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    salvarDados() {
      var array_item = "";
      if (this.typeSelect === "signature") {
        array_item = this.selectAssing;
      } else {
        array_item = this.selectCourse;
      }

      var toggle = "";
      if (this.gratuito === true) {
        toggle = "on";
      } else {
        toggle = "off";
      }

      let data = {
        id: "store",
        free_courses: toggle,
        type: this.typeSelect,
        item_free_id: JSON.stringify(array_item),
      };

      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then((resp) => {
          //console.log("meta comments update", resp);
          this.getMeta();
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    customLabel({ title }) {
      return `${title}`;
    },
    openSignature() {
      this.pageActualSignature = 1;
      this.AllSignature = [];
      this.getAllSignature();
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.addNextPageAssing();
      }
    },
    addNextPageAssing() {
      if (this.lastPageSignature >= this.pageActualSignature) {
        this.pageActualSignature = this.pageActualSignature + 1;
        this.getAllSignature("push");
      }
    },
    getAllSignature(action) {
      this.$root.$emit("loadOn");
      if (this.pageActualSignature === null) {
        this.pageActualSignature = 1;
      }

      var url = "page=" + this.pageActualSignature;
      serviceSignature
        .search(url)
        .then((resp) => {
          //console.log("todas as assinaturas", resp);
          var data = resp.data;
          this.pageActualSignature = resp.current_page;
          this.lastPageSignature = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllSignature.push(element);
            }
          } else {
            this.AllSignature = data;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    openCourse() {
      this.pageActualClass = 1;
      this.pageActualCourse = 1;
      this.AllCopageActualCourse = [];
      this.AllClass = [];
      this.getAllClass();
    },
    reachedEndOfList2(reached) {
      if (reached) {
        this.addNextPageAssing2();
      }
    },
    addNextPageAssing2() {
      //console.log("sssssssssssssssssssssssss", this.pageActualClass + 1);
      if (this.lastPageClass >= this.pageActualClass) {
        //console.log("sssssssssssssssssssssssss", this.pageActualClass + 1);
        this.pageActualClass = this.pageActualClass + 1;
        this.getAllClass("push");
      }
    },
    getAllClass(action) {
      this.$root.$emit("loadOn");
      if (this.pageActualClass === null) {
        this.pageActualClass = 1;
      }
      var url = "page=" + this.pageActualClass;
      serviceClass
        .search(url)
        .then((resp) => {
          //console.log("todas as classes", resp);
          var data = resp.data;
          this.pageActualClass = resp.current_page;
          this.lastPageClass = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllClass.push(element);
            }
          } else {
            this.AllClass = data;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    updateFreeCourse() {},
  },
};
</script>

<style lang="scss" scoped>
.allPlans {
  .to-plans {
    margin: 60px auto !important;
    display: flex;
    justify-content: center;
  }
  span.badge.badge-secondary {
    background: #ffffff54 !important;
    padding-top: 7px;
    height: 23px;
  }
  .between {
    justify-content: space-between;
  }
  .text-white {
    color: #fff !important;
  }
  .details {
    svg {
      margin-top: 5px;
      margin-right: 15px;
    }
  }
  // .grid-plans {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(1fr, auto));;
  //   gap: 12px;
  // }
  .plan {
    padding: 30px;
    transition: 0.4s;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }
  .plan:hover {
    border: 1px solid #ededf0;
  }
  .recomended {
    background: var(--maincolor);
  }
  .w100 {
    width: 100% !important;
  }
  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: var(--fontcolor);
  }
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: var(--fontcolor);
  }
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #81858e;
  }
}

.spaceColor {
  margin-top: 20px;
  margin-bottom: 20px;
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--fontcolor2);
    margin-bottom: 5px;
  }
  .subTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #81858e;
  }
  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor2);
    margin-bottom: 5px;
  }
  .flex {
    display: flex;
  }
}

select {
  border: 0.5px solid #ededf0 !important;
  box-sizing: border-box;
  border-radius: 5px;
  height: 55px;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: lighter;
  overflow: hidden !important;
  resize: none !important;
}

.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.form-group + .form-group {
  margin-top: 15px;
}
/* // codigo de verificação g digital */
.codigo-verifica {
  max-width: 60%;
}
@media screen and (max-width: 768px) {
  .codigo-verifica {
    max-width: 100%;
  }
}
.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}

input {
  border: 1px solid var(--bordercolor);
  box-sizing: border-box;
  border-radius: 5px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  overflow: hidden !important;
  resize: none !important;
}
input:hover,
input:focus {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

/* lista de entregas */
.lista-entrega h5 {
  margin-bottom: 20px;
  color: #2133d2;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 20px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 400px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
}
.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}
/* // co-seller */
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: #333;
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
}
</style>
