<template>
  <div class="base" data-anima="top">
    <h1>Campos Personalizados</h1>
    <p class="explication">
      Os campos personalizados são para os novos usuarios do painel, ao entrar,
      é necessario preencher os campos personalizados para ter total acesso a
      Plataforma.
    </p>
    <div class="line"></div>
    <div class="gridColum">
      <div>
        <h2><customIcon class="mr-2" /> Configurar Campos</h2>
        <b-form-group
          class="label-custom"
          label="Tipo do Campo"
          label-for="name"
        >
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-form-group>
        <b-form-group
          class="label-custom"
          label="Nome do Campo"
          label-for="name"
        >
          <b-form-input
            v-model="label"
            placeholder="Nome do Campo"
            class="mb-3"
          ></b-form-input>
        </b-form-group>
        <BaseButton @click="addFind" v-if="label" variant="primary" class="mb-3 w100"
          >Adicionar Campo</BaseButton
        >
        <BaseButton @click="saveAll" v-if="!label" variant="primary" class="mb-3 w100"
          >Salvar Campos</BaseButton
        >
      </div>
      <div>
        <h2><customIcon class="mr-2" />Campos</h2>
        <div class="spaceModalFake">
          <div
            v-for="(find, index) in finds"
            :key="find.label"
            class="alingItens"
          >
            <b-form-group
              class="label-custom"
              :label="find.name"
              name-for="name"
            >
              <b-form-input
                v-if="find.type === 'text'"
                v-model="find.value"
                :placeholder="find.name"
                :key="index"
              ></b-form-input>
              <b-form-textarea
                v-if="find.type === 'textarea'"
                v-model="find.value"
                :placeholder="find.name"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <svg
              @click="deleteItem(find, index)"
              class="delete"
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7864 1.62085L0.973389 14.4338M0.973389 1.62085L13.7864 14.4338"
                stroke="#FF0C37"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <b-row v-if="!finds.length" class="justify-content-center">
            <p class="nao-encontrado">Nenhum campo encontrado</p>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomFieldsService from "@/services/resources/CustomFieldsService";
const service = CustomFieldsService.build();
import customIcon from "./icons/custom.vue";

export default {
  components: { customIcon },
  data() {
    return {
      finds: [],
      label: "",
      selected: "text",
      options: [
        { value: "text", text: "Campo de Texto" },
        { value: "textarea", text: "Campo de Comentario" },
      ],
    };
  },
  mounted() {
    this.getCustomFields();
  },
  methods: {
    deleteItem(item, index) {
      if (item.id) {
        service
          .destroy(item.id)
          .then(() => {
            this.$bvToast.toast("Campo Deletado com Sucesso!", {
              title: "Campos Personalizados!",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.finds.splice(index, 1);
          })
          .catch(() => {
            this.$bvToast.toast("Erro ao deletar Campo!", {
              title: "Campos Personalizados!",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      } else {
        this.$bvToast.toast("Campo Deletado com Sucesso!", {
          title: "Campos Personalizados!",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.finds.splice(index, 1);
      }
    },
    saveAll() {
      var campos = this.finds;
      for (let i = 0; i < campos.length; i++) {
        const campo = campos[i];
        if (campo.id) {
          let data = {
            id: campo.id,
            name: campo.name,
            type: campo.type,
          };
          service
            .postID(data)
            .then(() => {
              //console.log("post-fields", resp);
              this.getCustomFields();
            })
            .catch(() => {
              //console.log(err);
              this.getCustomFields();
            });
        } else {
          service
            .create(campo)
            .then(() => {
              //console.log("post-fields", resp);
              this.getCustomFields();
            })
            .catch(() => {
              //console.log(err);
              this.getCustomFields();
            });
        }
      }
      this.$bvToast.toast("Campos Salvos com Sucesso!", {
        title: "Campos Personalizados!",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    getCustomFields() {
      service.search().then((resp) => {
        this.finds = resp;
      });
    },
    addFind() {
      this.finds.push({ type: this.selected, name: this.label });
      this.selected = "text";
      this.label = "";
    },
  },
};
</script>
<style lang="scss" scoped>
svg.delete {
  margin-left: 10px;
  margin-top: 10px;
  padding: 2px;
  cursor: pointer;
}
.alingItens {
  display: grid;
  grid-template-columns: 18fr 1fr;
  align-items: center;
}
.preview-itens {
  div {
    display: flex;
    align-items: center;
    gap: 15px;
    svg {
      padding: 3px;
    }
  }
}

.addInput2 {
  width: 100%;
  height: 45px;
  left: 505.08px;
  top: 52.43px;
  background: rgba(33, 51, 210, 0.1);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2133d2;
}

.addInput {
  width: 100%;
  height: 45px;
  left: 505.08px;
  top: 52.43px;
  background: #00e4a0;
  border-radius: 5px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

//novo estilo
h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 15px;
}

h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 15px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}

.base {
  width: calc(100% - 50px);
  padding-right: 50px;
}

.explication {
  font-weight: 400;
  font-size: 13px;
  color: #81858e;
  margin-bottom: 25px;
}

.spaceModalFake {
  border-radius: 5px;
}

.gridColum {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  input,
  textarea,
  select {
    border: 1px solid #ededf0 !important;
    border-radius: 5px;
    height: 55px;
    font-weight: 600;
    font-size: 14px;
    color: #81858e !important;
  }
  input:hover,
  select:hover,
  textarea:hover,
  input:focus,
  select:focus,
  textarea:focus {
    border: 1px solid var(--maincolor) !important;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03) !important;
    color: var(--fontcolor) !important;
  }
}

select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat !important;
}

.label-custom {
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0.2px !important;
  color: var(--fontcolor) !important;
}

@media screen and (max-width: 768px) {
  .gridColum{
    grid-template-columns: 1fr !important;
  }
}
</style>