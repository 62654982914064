<template>
  <div class="base">
    <div data-anima="top">
      <h1>Aparência do sistema</h1>
      <div class="line"></div>
      <Item
        title="Logotipo"
        @remove="remove"
        :value="null"
        param="logo"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadLogo"
            v-if="!logo"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="logo"
              plain
            ></b-form-file>
            {{ logo }}
          </div>
          <div class="image" @click="showModalUploadLogo" v-else>
            <img :src="logo" alt="logo" />
          </div>
          <div>
            <span
              >Dimensão Recomendada: 100x100
              {{ logo ? " - Clique para selecionar nova imagem" : "" }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Imagotipo para Menu Aberto (Opcional)"
        :value="null"
        param="logomarca"
        @remove="remove"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer, display: flex;"
            @click="showModalUploadLogomarca"
            v-if="!logomarca"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="logomarca"
              plain
            ></b-form-file>
          </div>
          <div class="image" @click="showModalUploadLogomarca" v-else>
            <img :src="logomarca" alt="logomarca" />
          </div>
          <div>
            <span
              >Dimensão Recomendada: 300x90
              {{
                logomarca ? " - Clique para selecionar nova imagem" : ""
              }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Imagem de fundo"
        :value="null"
        @remove="remove"
        param="login_background_image"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadBackground"
            v-if="!login_background_image"
          >
            <b-form-file
              class="mt-3 input-file"
              v-model="login_background_image"
              plain
            ></b-form-file>
          </div>
          <div class="image" @click="showModalUploadBackground" v-else>
            <img :src="login_background_image" alt="login_background_image" />
          </div>
          <div>
            <span
              >Dimensão Recomendada: 2000x2000
              {{
                login_background_image
                  ? " - Clique para selecionar nova imagem"
                  : ""
              }}</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Cor principal do painel"
        :value="null"
        param="rating_home"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="flex">
            <v-swatches
              v-model="main_color"
              :swatches="swatches"
              row-length="1"
              @input="changedColor"
              inline
            >
            </v-swatches>
            <div class="ml-5 flex">
              <div><span>Personalizar Cor:</span></div>
              <InputColorPicker
                class="ml-3"
                @change="changedColor"
                v-model="main_color"
              />
            </div>
          </div>
        </template>
      </Item>
      <Item
        title="Definir Curso em Destaque"
        descricao="Selecione o curso que ficara em destaque no seu painel."
        :value="null"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <b-form-select
              placeholder="Selecione um Curso"
              v-model="featured_course"
              :options="courseOptions"
              @change="updateCourse"
            >
            </b-form-select>
          </div>
        </template>
      </Item>
      <Item
        title="Editar nome, descrição e subdomínio do painel"
        :value="null"
        @update="update"
        param="termsOfUseActivated"
        descricao=""
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="openModalConfigPainel" variant="link-info"
              >Clique para editar informações do painel</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        title="Ocultar cursos e categorias"
        :value="hide_courses"
        @update="update"
        param="hide_courses"
        descricao="Se ativado e o aluno não tiver o curso, ele não sera apresentado no sistema."
      >
        <template v-slot:icon>
          <categoryIcon />
        </template>
      </Item>
      <Item
        title="Capas dos cursos na vertical (Modelo capa de filme)"
        :value="home_vertical"
        @update="update"
        param="home_vertical"
        descricao="Se essa opção estiver ativa, os cursos serão listados verticalmente, sera necessario subir as capas dos cursos novamente."
      >
        <template v-slot:icon>
          <capaIcon />
        </template>
      </Item>
      <Item
        title="Modo Carrossel (Desktop)"
        :value="home_carousel"
        @update="update"
        param="home_carousel"
        descricao="Se essa opção estiver ativa, os cursos serão listados em uma linha na Desktop."
      >
        <template v-slot:icon>
          <gridIcon />
        </template>
      </Item>
      <Item
        title="Listar cursos fora das categorias"
        :value="without_category"
        @update="update"
        param="without_category"
        descricao="Se essa opção estiver ativa, os modulos serão listados na home."
      >
        <template v-slot:icon>
          <listIcon />
        </template>
      </Item>
      <Item
        title="Mostrar disponibilidade do curso"
        descricao="Se esta opção estiver ativa, no card será mostrada a disponibilidade do curso."
        param="is_available_course"
        :value="is_available_course"
        @update="update"
      >
        <template v-slot:icon>
          <checkIcon />
        </template>
        <template v-slot:sub>
          <section v-if="is_available_course">
            <b-row class="mt-3">
              <b-col cols="3" md="2" class="flex align-items-center">
                <b-form-group label="Disponível" label-for="available_color">
                  <InputColorPicker
                    class="ml-3"
                    :class="blackAndWhiteClassAvailable"
                    @change="changeAvailableColor"
                    id="available_color"
                    v-model="available_color"
                    type="color"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2" class="flex">
                <is-available
                  v-if="!loading"
                  :has-access="true"
                  :is-example="true"
                ></is-available>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3" md="2" class="flex align-items-center">
                <b-form-group
                  label="Indisponível"
                  label-for="not_available_color"
                >
                  <InputColorPicker
                    class="ml-3"
                    :class="blackAndWhiteClassNotAvailable"
                    @change="changeNotAvailableColor"
                    id="not_available_color"
                    v-model="not_available_color"
                    type="color"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2" class="flex align-items-center">
                <is-available
                  v-if="!loading"
                  :has-access="false"
                  :is-example="true"
                ></is-available>
              </b-col>
            </b-row>
          </section>
        </template>
      </Item>
      <Item
        title="Imagem de fundo do Painel"
        :value="null"
        @remove="remove"
        param="panel_background_image"
        descricao="">
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div
            style="cursor: pointer"
            @click="showModalUploadBackgroundPanel"
            v-if="!panel_background_image">
            <b-form-file
              class="mt-3 input-file"
              v-model="panel_background_image"
              plain>
            </b-form-file>
          </div>
          <div class="image" @click="showModalUploadBackgroundPanel" v-else>
            <img :src="panel_background_image" alt="panel_background_image" />
          </div>
          <div>
            <span>
              Dimensão Recomendada: 2000x2000
              {{
                panel_background_image
                  ? " - Clique para selecionar nova imagem"
                  : ""
              }}
            </span>
          </div>
        </template>
      </Item>
      <Item
        title="Tema do sistema é fixo"
        :value="theme_fixed_painel"
        @update="update"
        param="theme_fixed_painel"
        descricao="Se essa opção estiver ativa o tema do painel não podera ser alterado pelo aluno."
      >
        <template v-slot:icon>
          <themeIcon />
        </template>
        <template v-slot:sub>
          <div v-if="theme_fixed_painel">
            <b-form-radio-group
              id="radio-group-2"
              class="mt-3"
              v-model="theme_fixed_painel_color"
              name="Escolha um tema"
              @change="changeValueThemeFixed"
            >
              <b-form-radio value="light">Tema Claro</b-form-radio>
              <b-form-radio value="dark">Tema Escuro</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
      <Item
        title="Versão do club"
        :value="null"
        @update="update"
        param="club_version"
        descricao="Escolha qual versão do club deseja utilizar"
      >
        <template v-slot:icon>
          <customIcon />
        </template>
        <template v-slot:sub>
          <div>
            <b-form-radio-group
              id="radio-group-2"
              class="mt-3"
              v-model="club_version"
              name="Escolha uma versão"
              @change="changeClubVersion"
            >
              <b-form-radio value="v1">Versão 1</b-form-radio>
              <b-form-radio value="v2">Versão 2</b-form-radio>
            </b-form-radio-group>
          </div>
        </template>
      </Item>
    </div>
    <ModalConfigPainel />
    <ModalUpload />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import Item from "./Item.vue";

import categoryIcon from "./icons/category.vue";
import customIcon from "./icons/custom.vue";
import capaIcon from "./icons/capa.vue";
import gridIcon from "./icons/grid.vue";
import listIcon from "./icons/list.vue";
import themeIcon from "./icons/theme.vue";
import checkIcon from "./icons/check.vue";

import ModalConfigPainel from "@/components/ModalConfigPainel.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import InputColorPicker from "vue-native-color-picker";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import IsAvailable from "@/components/home/IsAvailable.vue";
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  components: {
    Item,
    InputColorPicker,
    VSwatches,
    customIcon,
    ModalUpload,
    capaIcon,
    gridIcon,
    themeIcon,
    listIcon,
    categoryIcon,
    checkIcon,
    ModalConfigPainel,
    IsAvailable,
  },
  data() {
    return {
      main_color: "#00E4A0",
      swatches: [
        ["#00E4A0"],
        ["#2133D2"],
        ["#FF0C37"],
        ["#FFCB37"],
        ["#2ED7EE"],
        ["#5D21D2"],
        ["#5A68DF"],
        ["#FF37D3"],
      ],
      without_category: false,
      home_carousel: false,
      home_vertical: false,
      hide_courses: false,
      theme_fixed_painel: false,
      theme_fixed_painel_color: "dark",
      club_version: "v1",
      login_background_image: "",
      login_background_image_id: null,
      panel_background_image: "",
      panel_background_image_id: null,
      logomarca: "",
      logo: "",
      is_available_course: false,
      available_color: "#2133d2",
      not_available_color: "#ffcb37",
      loading: false,
      AllCourses: [],
      courseSelect: "",
      featured_course: "",
    };
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
    blackAndWhiteClassNotAvailable() {
      return {
        "black-selected":
          this.not_available_color === "#ffffff" ||
          this.not_available_color === "#000000",
        "white-selected":
          this.not_available_color === "#ffffff" ||
          this.not_available_color === "#000000",
      };
    },
    blackAndWhiteClassAvailable() {
      return {
        "black-selected":
          this.available_color === "#ffffff" ||
          this.available_color === "#000000",
        "white-selected":
          this.available_color === "#ffffff" ||
          this.available_color === "#000000",
      };
    },
    courseOptions() {
      return this.AllCourses.map((courses) => ({
        text: courses.title,
        value: courses.id,
      }));
    },
  },
  methods: {
    getKeys(key) {
      var keys = [];
      if (key) {
        keys = [key];
      } else {
        keys = [
          "main_color",
          "without_category",
          "home_carousel",
          "home_vertical",
          "hide_courses",
          "theme_fixed_painel",
          "theme_fixed_painel_color",
          "login_background_image",
          "panel_background_image",
          "logomarca",
          "logo",
          "club_version",
          "is_available_course",
          "available_color",
          "not_available_color",
          "featured_course",
        ];
      }
      var result = "";
      for (let i = 0; i < keys.length; i++) {
        if (keys.length - 1 == i) {
          result += `keys[]=${keys[i]}`;
        } else {
          result += `keys[]=${keys[i]}&`;
        }
      }
      serviceMeta
        .search(result)
        .then((resp) => {
          if (resp.main_color !== undefined) {
            this.main_color = resp.main_color;
          }
          if (resp.login_background_image !== undefined) {
            if (resp.login_background_image) {
              this.login_background_image = resp.login_background_image.cdn_url;
            }
          }
          if (resp.panel_background_image !== undefined) {
            this.panel_background_image = resp.panel_background_image?.cdn_url;
          }
          if (resp.logomarca !== undefined) {
            this.logomarca = resp.logomarca;
          }
          if (resp.logo !== undefined) {
            this.logo = resp.logo;
          }
          if (resp.without_category !== undefined) {
            if (
              resp.without_category === "off" ||
              resp.without_category === null
            ) {
              this.without_category = false;
            } else {
              this.without_category = true;
            }
          }
          if (resp.home_vertical !== undefined) {
            if (resp.home_vertical === "off" || resp.home_vertical === null) {
              this.home_vertical = false;
            } else {
              this.home_vertical = true;
            }
          }
          if (resp.home_carousel !== undefined) {
            if (resp.home_carousel === "off" || resp.home_carousel === null) {
              this.home_carousel = false;
            } else {
              this.home_carousel = true;
            }
          }
          if (resp.hide_courses !== undefined) {
            if (resp.hide_courses === null || resp.hide_courses === "on") {
              this.hide_courses = true;
            } else {
              this.hide_courses = false;
            }
          }
          if (resp.theme_fixed_painel !== undefined) {
            if (resp.theme_fixed_painel === "off") {
              this.theme_fixed_painel = false;
            } else {
              this.theme_fixed_painel = true;
            }
          }

          if (resp.club_version === "v1") {
            this.club_version = "v1";
          } else if (resp.club_version === "v2") {
            this.club_version = "v2";
          }

          if (resp.theme_fixed_painel_color !== undefined) {
            if (resp.theme_fixed_painel_color !== null) {
              this.theme_fixed_painel_color = resp.theme_fixed_painel_color;
            } else {
              this.changeValueThemeFixed("light");
            }

            this.$root.$emit("loadOff");

            document.documentElement.setAttribute(
              "data-theme",
              this.theme_fixed_painel_color
            );

            this.$root.$emit("loadTheme");
            this.$root.$emit("loadsettheme");
          }

          if (resp.is_available_course !== undefined) {
            this.is_available_course =
              resp.is_available_course === "on" ?? false;
          }
          if (resp.available_color !== undefined) {
            this.available_color = resp.available_color;
          }
          if (resp.not_available_color !== undefined) {
            this.not_available_color = resp.not_available_color;
          }
          if (resp.featured_course !== undefined) {
            this.featured_course = resp.featured_course;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mensageUpdate() {
      this.$bvToast.toast("Configuração atualizada com sucesso!", {
        title: "Atualizado!",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    update(item) {
      let data = {
        id: item[1],
        value: item[0] ? "on" : "off",
      };

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(item[1]);
      });
    },
    updateCourse() {
      let data = {
        id: "featured_course",
        value: this.featured_course,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(data.id);
      });
    },
    changeValueThemeFixed(theme) {
      let data = {
        id: "theme_fixed_painel_color",
        value: theme,
      };

      Cookies.set("themeClub", theme, { expires: 365 });
      this.$store.commit("ClubTheme", theme);

      document.documentElement.setAttribute("data-theme", theme);
      this.$root.$emit("loadTheme");
      this.$root.$emit("loadsettheme");

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("theme_fixed_painel_color");
      });
    },

    changeClubVersion(version) {
      let data = {
        id: "club_version",
        value: version,
      };

      if (version === "v2") {
        Cookies.set("clubVersion", "v2", { expires: 365 });
        this.$store.commit("clubVersion", "v2");
      } else {
        Cookies.set("clubVersion", "v1", { expires: 365 });
        this.$store.commit("clubVersion", "v1");
      }

      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("club_version");
      });
    },
    changedColor() {
      var data = {
        id: "main_color",
        value: this.main_color,
      };
      serviceMeta.postID(data).then(() => {
        if (this.main_color !== null) {
          const d = document;
          d.documentElement.setAttribute("data-color", this.main_color);
          document.documentElement.style.setProperty(
            "--maincolor",
            this.main_color
          );
          document.documentElement.style.setProperty(
            "--maincolorn",
            this.$func.convertColor(this.main_color, 210)
          );
          document.documentElement.style.setProperty(
            "--maincolortrans",
            this.main_color + "0f"
          );
        }
        this.mensageUpdate();
        this.getKeys("main_color");
        this.$root.$emit("changedmaincolor", this.color);
        this.$root.$emit("loadOff");
      });
    },
    updateLogoPainel() {
      var data = {
        id: "logo",
        value: this.logo,
      };
      serviceMeta.postID(data).then(() => {
        this.getKeys("logo");
        this.mensageUpdate();
        this.$root.$emit("changedlogo");
      });
    },
    updateLogomarcaPainel() {
      var data = {
        id: "logomarca",
        value: this.logomarca,
      };
      serviceMeta.postID(data).then(() => {
        this.getKeys("logomarca");
        this.mensageUpdate();
        this.$root.$emit("changedlogomarca");
      });
    },
    updateBackgroundLogin() {
      var data = {
        id: "login_background_image",
        value: this.login_background_image_id,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("login_background_image");
      });
    },
    updateBackgroundPanel() {
      var data = {
        id: "panel_background_image",
        value: this.panel_background_image_id,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("panel_background_image");
      });
    },
    remove(item) {
      var data = {
        id: item,
        value: null,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys(item);
      });
    },
    openModalConfigPainel() {
      this.$root.$emit("openeditproject");
      this.$root.$emit("bv::show::modal", "modal-config-painel", "#btnShow");
    },
    showModalUploadLogo() {
      this.$root.$emit("logopainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadLogomarca() {
      this.$root.$emit("logomarcapainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadBackground() {
      this.$root.$emit("backgroundlogin");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadBackgroundPanel() {
      this.$root.$emit("backgroundpanel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    changeAvailableColor(){
      this.loading = true;
      let data = {
        id: "available_color",
        value: this.available_color,
      };

      serviceMeta.postID(data).finally(() => (this.loading = false));
    },
    changeNotAvailableColor() {
      this.loading = true;
      let data = {
        id: "not_available_color",
        value: this.not_available_color,
      };

      serviceMeta.postID(data).finally(() => (this.loading = false));
    },
    getAllCourses() {
      var url = "all/" + this.$store.getters.currentSite.currentMember.id;
      serviceCourse
        .search(url)
        .then((resp) => {
          this.AllCourses = resp.data;
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
  },
  mounted() {
    this.getKeys();
    this.getAllCourses();
    this.$root.$on("newlogopainel", (data) => {
      this.logo = data.url;
      this.updateLogoPainel();
    });
    this.$root.$on("newlogomarcapainel", (data) => {
      this.logomarca = data.url;
      this.updateLogomarcaPainel();
    });
    this.$root.$on("newbackgroundlogin", (data) => {
      this.login_background_image = data.url;
      this.login_background_image_id = data.id;
      this.updateBackgroundLogin();
    });
    this.$root.$on("newbackgroundpanel", (data) => {
      this.panel_background_image = data.url;
      this.panel_background_image_id = data.id;
      this.updateBackgroundPanel();
    });
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  margin-bottom: 20px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 13px;
    color: #81858e;
    margin: 0;
  }
}

.vue-swatches__swatch {
  border-radius: 9px !important;
}

.inputSub {
  height: 40px;
  border: 1px solid #ededf0 !important;
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  color: #81858e;
  width: 40%;
  margin-top: 10px;
}

.base {
  width: calc(100% - 50px);
  padding-right: 50px;
}

.text {
  font-weight: 600;
  font-size: 13px;
  color: var(--fontcolor2);
  margin-bottom: 5px;
}

.flex {
  display: flex;
}

.icp__input {
  width: 42px !important;
  height: 42px !important;
  border-radius: 9px !important;
  background: #ffffff00 !important;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}

.input-file {
  border: 1px solid #ededf0 !important;
  padding: 13px;
  border-radius: 5px;
  pointer-events: none;
}

.image {
  cursor: pointer;
  height: 100px;
  border: 1px solid #ededf0 !important;
  padding: 13px;
  border-radius: 5px;
  img {
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 2px;
  }
}

.black-selected {
  border: 2px solid white;
  &.icp__input[data-v-512dc202]::-moz-color-swatch {
    border-radius: 7px;
  }
}

.white-selected {
  border: 2px solid black;
  &.icp__input[data-v-512dc202]::-moz-color-swatch {
    border-radius: 7px;
  }
}
</style>
