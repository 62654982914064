<template>
    <b-modal id="modal-delete-site" hide-footer>
        <div class="allContentModal">
            <div>
                <img class="" src="@/assets/icons/delete-site.svg" alt="alert">
            </div>
            <div class="spaceText1">
                <p class="text1">Realmente deseja deletar o Painel?</p>
            </div>
            <div class="spaceText2">
                <p class="text2">Todos os cursos, modulos, aulas e midias do painel serão deletados!</p>
                <p class="text2">Nome: {{ selectedSite.name }}</p>
                <p class="text2">Subdomínio: {{ selectedSite.subdomain }}</p>
            </div>
            <div>
                <b-form-group
                    label="Confirme seu Subdomínio"
                    label-for="name"
                    class="labelxx"
                >
              <input
                type="text"
                class="form-control"
                v-model="subdomain"
                name="subdomain"
                placeholder="Digite seu Subdomínio"
              />
            </b-form-group>
            </div>
            <div class="spaceBtns">
                <button class="button" @click="toCancel">Cancelar</button>
                <button class="button2" @click="toDelete">Deletar</button>
            </div>
        </div>
    </b-modal>
</template>
<script>
import notify from "@/services/libs/notificacao";
import SiteService from "@/services/resources/SiteService";
const serviceSite = SiteService.build();

export default {
    props:['selectedSite', 'allSites'],
    components: {
    },
    data(){
        return{
            subdomain:''
        }
    },
    methods:{
        toCancel(){
            this.$root.$emit('bv::hide::modal', 'modal-delete-site', '#btnShow');
        },
        toDelete(){
            let data = {
                id: this.selectedSite.id+'?subdomain='+this.subdomain
            }
            serviceSite
            .destroy(data)
            .then((resp) => {
                //console.log("get string sites", resp);
                notify('sucesso', 'Painel deletado!');
                this.$root.$emit('bv::hide::modal', 'modal-delete-site', '#btnShow');
                if(this.allSites.member.sites.length === 1){
                    this.$store.dispatch("logoutRequest").catch(function () {});
                }else{
                    var array = [];
                    var sites = this.allSites.member.sites;
                    for (let i = 0; i < sites.length; i++) {
                        //console.log(sites[i]);
                        if(sites[i].id !== this.selectedSite.id){
                            //console.log(sites[i]);
                            array.push(sites[i]);
                        }
                    }
                    this.$store.dispatch("blogSelected", array[0]);
                }
            }).catch(err => {
                //console.log(err.response);
                notify('erro', 'Erro ao deletar!');
            });
        }
    },
    mounted(){
    }
}
</script>
<style lang="scss">
#modal-delete-site{
    z-index: 99999999999;
    .modal-header{
        border: none;
    }
}
</style>
<style lang="scss" scoped>
#modal-delete-site{
    input {
        border: 1px solid #ededf0;
        box-sizing: border-box;
        border-radius: 5px;
        height: 60px;
        font-size: 14px;
        padding: 20px;
        transition: .3s;
    }
    input:hover,
    input:focus {
        border: 1px solid #FC2B36;
        box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
        background: rgba(228, 0, 0, 0.05);
        color: var(--fontcolor);
        font-weight: normal;
    }
    input:focus {
        font-weight: 600;
    }
    .labelxx {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor);
        text-align: initial;
    }
    .allContentModal {
        padding: 0px 10px;
        margin: 0 auto;
        display: block;
        text-align: -webkit-center;
    }
    .spaceText2{
        margin-bottom: 20px;
    }
    .spaceText1{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .text1{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.2px;
        color: #FC2B36;
        text-align: center;
    }
    .text2{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #818181;
    }
    .spaceBtns{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .button{
        background: var(--backgroundcolor);
        border-radius: 6px;        
        width: 200px;
        height: 45px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        border: none;
        margin: 0 5px;
    }
    .button2{
        background: #FC2B36;
        border-radius: 6px;        
        width: 200px;
        height: 45px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        border: none;
        margin: 0 5px;
    }
}
</style>